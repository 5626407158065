<template>
    <el-scrollbar class="right-content">
        <div class="score-header">本次培训成绩</div>
        <div class="score-info">
            <div class="info-item" v-if="scoreContent.hasOwnProperty('scene')">
                当前场景：{{scoreContent.scene}}
            </div>
            <div class="info-item">
                平均响应时间：
                <span class="time" :class="scoreContent.stu_response_time <= scoreContent.response_time ? 'green' : 'red'">
                                    {{scoreContent.stu_response_time}}<span class="unit">s</span>
                                    <i class="iconfont">{{scoreContent.stu_response_time <= scoreContent.response_time ? '&#xe656;' : '&#xe658;'}}</i>
                                </span>
            </div>
            <div class="info-item">
                本次成绩：
                <span class="time" :class="(scoreContent.essay_score + scoreContent.operation_score) < 60 ? 'red' : 'green'">{{scoreContent.essay_score + scoreContent.operation_score}}</span>
            </div>
        </div>
        <div class="score-info" style="padding: 0 20px 0 10px; margin-bottom: 10px">
            <div class="info-item">
                问答题：
                <span class="time" :class="scoreContent.essay_score < 60 ? 'red' : 'green'">{{scoreContent.essay_score}}</span>
            </div>
        </div>
        <template v-if="scoreContent.essay_content.length > 0">
            <div class="error-box" v-for="(scoreItem, scoreIndex) in scoreContent.essay_content" :key="`essay_content_${scoreIndex}`">
                <div class="error">
                    <div class="left">错误点{{scoreIndex + 1}}:</div>
                    <div class="right">{{scoreItem.wrong_point}}</div>
                </div>
                <div class="model-answer">
                    <div class="header">参考答案:</div>
                    <div class="answer-box">
                        <div class="item">
                            <div class="left blue-round">问</div>
                            <div class="right">{{scoreItem.question}}</div>
                        </div>
                        <div class="item">
                            <div class="left yellow-round">答</div>
                            <div class="right" v-html="scoreItem.answer ? scoreItem.answer : '暂无回答'"></div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div v-else style="text-align: center; color: #999; margin: 100px 0">暂无错题</div>
        <div class="score-info" style="padding: 0 20px 0 10px; margin-bottom: 10px">
            <div class="info-item">
                操作题：
                <span class="time" :class="scoreContent.operation_score < 60 ? 'red' : 'green'">{{scoreContent.operation_score}}</span>
            </div>
        </div>
        <template v-if="scoreContent.operation_content.length > 0">
            <div class="error-box" v-for="(scoreItem, scoreIndex) in scoreContent.operation_content" :key="`operation_content_${scoreIndex}`">
                <div class="error">
                    <div class="left">错误点{{scoreIndex + 1}}:</div>
                    <div class="right">{{scoreItem.wrong_point}}</div>
                </div>
                <div class="model-answer">
                    <div class="header">参考答案:</div>
                    <div class="answer-box">
                        <div class="item">
                            <div class="left blue-round">问</div>
                            <div class="right">{{scoreItem.question}}</div>
                        </div>
                        <div class="item">
                            <div class="left yellow-round">答</div>
                            <div class="right">
                                <div style="color: #0C6AFF; background: #DEEBFF; display: inline-block; padding: 0 6px; border-radius: 2px">{{scoreItem.operation}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div v-else style="text-align: center; color: #999; margin: 100px 0">暂无错题</div>
</el-scrollbar>
</template>

<script>
    export default {
        name: "ScorePage",
        props: {
            scoreContent: {
                type: Object,
                default:() => {}
            }
        },
    }
</script>

<style scoped lang="scss">
    .right-content {
        flex: 1;
        height: 1%;
        padding: 10px 0 0;
        & > ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__wrap {

            }
        }
        .score-header {
            line-height: 1;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
        }
        .score-info {
            color: #333;
            font-size: 14px;
            padding: 20px 20px 20px 10px;
            .info-item {
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
                .time {
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 1;
                    .iconfont {
                        font-size: 16px;
                        margin-left: 10px;
                        font-weight: normal;
                    }
                }
            }
        }
        .error-box {
            padding: 10px;
            border: 1px solid #eee;
            margin: 0 20px 20px 10px;
            .error {
                display: flex;
                .left {
                    color: #666;
                }
                .right {
                    width: 1%;
                    flex: 1;
                    margin-left: 5px;
                }
            }
            .model-answer {
                margin-top: 12px;
                .header {
                    color: #666;
                    line-height: 1;
                }
                .answer-box {
                    .item {
                        display: flex;
                        margin-top: 8px;
                        .left {
                            width: 22px;
                            height: 22px;
                            font-size: 12px;
                            border-radius: 50%;
                            text-align: center;
                            line-height: 22px;
                            &.blue-round {
                                color: #0C6AFF;
                                background: #DEEBFF;
                            }
                            &.yellow-round {
                                color: #FFB000;
                                background: #FFEFCB;
                            }
                        }
                        .right {
                            flex: 1;
                            width: 1%;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
    .red {
        color: #F61F03;
    }
    .green {
        color: #00DF69;
    }
</style>